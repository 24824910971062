import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { resetUser } from 'src/auth/utils/resetUser'
import { Application, UpdateDto } from 'switch-shared'
import { NetworkState } from '../../constants/network-state'
import ApplicationApiService from '../../services/switch-rest-api/rest-application.service'
import { updateSelectedApplicationGroupId } from '../application-groups/application-group.slice'

const applicationApiService = new ApplicationApiService()

export const fetchApplications = createAsyncThunk(
    'applications/fetchApplications',
    async (applicationGroupId: string) => {
        try {
            const res = await applicationApiService.getApplications({
                applicationGroupId,
                page: 0,
                limit: 1000,
            })
            return res
        } catch (error: any) {
            throw new Error(error.message)
        }
    }
)

export const createApplication = createAsyncThunk(
    'applications/createApplication',
    async (newAppObject: {}) => {
        try {
            const res =
                await applicationApiService.createApplication(newAppObject)
            return res
        } catch (error: any) {
            throw new Error(error.message)
        }
    }
)

export const updateApplication = createAsyncThunk(
    'applications/updateApplication',
    async ({ id, app }: { id: string; app: UpdateDto<Application> }) => {
        try {
            await applicationApiService.updateApplication(id, app)
            return {
                ...app,
                _id: id,
            }
        } catch (error: any) {
            throw new Error(error.message)
        }
    }
)

export const deleteApplication = createAsyncThunk(
    'applications/deleteApplication',
    async (id: string) => {
        try {
            const res = await applicationApiService.deleteApplication(id)
            return {
                ...res,
                _id: id,
            }
        } catch (error: any) {
            throw new Error(error.message)
        }
    }
)

export const uniqueHumanIdCheck = createAsyncThunk(
    'applications/uniqueHumanIdCheck',
    async (data: { collectionName: string; humanId: string }) => {
        try {
            const res = await applicationApiService.uniqueHumanIdCheck(data)
            return res
        } catch (error: any) {
            throw new Error(error.message)
        }
    }
)

interface applicationsSliceInterface {
    applicationNetworkStatus: {
        applications: NetworkState
        isUpdatingApplication: NetworkState
        isCreatingApplication: NetworkState
        isDeletingApplication: NetworkState
    }
    errors: {
        isUpdatingApplication: string | undefined
        isCreatingApplication: string | undefined
        isDeletingApplication: string | undefined
    }
    applications: Application[]
    applicationsCount: number
    locallySelectedApplicationId: string | undefined
    manualApplicationChange: boolean
    selectedApplicationId: string
}

const initialState: applicationsSliceInterface = {
    applicationNetworkStatus: {
        applications: NetworkState.NOT_STARTED,
        isUpdatingApplication: NetworkState.NOT_STARTED,
        isCreatingApplication: NetworkState.NOT_STARTED,
        isDeletingApplication: NetworkState.NOT_STARTED,
    },
    errors: {
        isUpdatingApplication: '',
        isCreatingApplication: '',
        isDeletingApplication: '',
    },
    applications: [],
    applicationsCount: 0,
    locallySelectedApplicationId: undefined,
    selectedApplicationId: '',
    manualApplicationChange: false,
}

const applicationsSlice = createSlice({
    name: 'applications',
    initialState,
    reducers: {
        updateSelectedApplicationId(state, action) {
            localStorage.setItem(
                'locallySelectedApplicationId',
                JSON.stringify(action.payload)
            )
            state.manualApplicationChange = true
            state.locallySelectedApplicationId = action.payload
        },
        updateSelectedAppId(state, action) {
            state.selectedApplicationId = action.payload
        },
        resetAppUpdateAndCreateState(state) {
            state.applicationNetworkStatus.isUpdatingApplication =
                NetworkState.NOT_STARTED
            state.applicationNetworkStatus.isCreatingApplication =
                NetworkState.NOT_STARTED
        },
    },
    extraReducers: (builder) => {
        // builder.addCase(signOutSuccess, (state) => initialState);
        builder
            .addCase(fetchApplications.pending, (state) => {
                state.applicationNetworkStatus.applications =
                    NetworkState.PENDING
            })
            .addCase(fetchApplications.fulfilled, (state, action) => {
                state.applicationNetworkStatus.applications =
                    NetworkState.SUCCESS

                const storedId = localStorage.getItem(
                    'locallySelectedApplicationId'
                )

                if (storedId) {
                    state.locallySelectedApplicationId = JSON.parse(storedId)
                } else {
                    if (action.payload?.value?.totalRecords === 1) {
                        state.locallySelectedApplicationId =
                            action.payload?.value.data[0]._id
                    } else {
                        state.locallySelectedApplicationId = 'All'
                    }
                }

                state.applicationsCount =
                    action.payload?.value?.totalRecords || 0
                state.applications = [
                    {
                        _id: 'All',
                        id: 'All',
                        name: 'All Applications',
                        applicationGroupId: '',
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    },
                    ...(action.payload?.value?.data ?? []),
                ]
            })
            .addCase(fetchApplications.rejected, (state) => {
                state.applicationNetworkStatus.applications = NetworkState.ERROR
            })
            .addCase(createApplication.pending, (state) => {
                state.applicationNetworkStatus.isCreatingApplication =
                    NetworkState.PENDING
            })
            .addCase(createApplication.fulfilled, (state, action) => {
                state.applicationNetworkStatus.isCreatingApplication =
                    NetworkState.SUCCESS
                toast.success('Application successfully created')
                state.applications = _.concat(
                    action.payload?.value,
                    state.applications
                )
                state.applicationNetworkStatus.applications =
                    NetworkState.NOT_STARTED
            })
            .addCase(createApplication.rejected, (state) => {
                toast.error('Error creating application')
                state.applicationNetworkStatus.isCreatingApplication =
                    NetworkState.ERROR
            })
            .addCase(updateApplication.pending, (state) => {
                state.applicationNetworkStatus.isUpdatingApplication =
                    NetworkState.PENDING
            })
            .addCase(updateApplication.fulfilled, (state, action) => {
                state.applications = state.applications.map((application) =>
                    application._id === action.payload?._id
                        ? ({ ...application, ...action.payload } as any)
                        : application
                )
                state.locallySelectedApplicationId = undefined
                state.applicationNetworkStatus.isUpdatingApplication =
                    NetworkState.SUCCESS
                toast.success('Success')
                state.applicationNetworkStatus.applications =
                    NetworkState.NOT_STARTED
            })
            .addCase(updateApplication.rejected, (state) => {
                toast.error('There was an error saving the application')
                state.applicationNetworkStatus.isUpdatingApplication =
                    NetworkState.ERROR
                state.locallySelectedApplicationId = undefined
            })
            .addCase(deleteApplication.pending, (state) => {
                state.applicationNetworkStatus.isDeletingApplication =
                    NetworkState.PENDING
            })
            .addCase(deleteApplication.fulfilled, (state, action) => {
                const storedId = localStorage.getItem(
                    'locallySelectedApplicationId'
                )

                // If the currently selected application is deleted
                if (storedId === JSON.stringify(action.payload._id)) {
                    localStorage.removeItem('locallySelectedApplicationId')
                }

                state.applicationNetworkStatus.isDeletingApplication =
                    NetworkState.SUCCESS
                state.applicationNetworkStatus.applications =
                    NetworkState.NOT_STARTED
            })
            .addCase(deleteApplication.rejected, (state) => {
                state.applicationNetworkStatus.isDeletingApplication =
                    NetworkState.ERROR
                state.locallySelectedApplicationId = undefined
            })
            .addCase(updateSelectedApplicationGroupId, () => {
                return initialState
            })
            .addCase(resetUser.fulfilled, () => {
                localStorage.removeItem('locallySelectedApplicationId')
                return initialState
            })
    },
})

export const {
    updateSelectedApplicationId,
    updateSelectedAppId,
    resetAppUpdateAndCreateState,
} = applicationsSlice.actions

export default applicationsSlice.reducer
