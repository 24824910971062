import {
    Close,
    Drafts,
    ErrorOutline,
    Info,
    Notifications,
    Warning,
} from '@mui/icons-material'
import {
    Avatar,
    Badge,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Popover,
    Skeleton,
    Typography,
    alpha,
    colors,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import dayjs from 'dayjs'
import { useContext, useEffect, useRef, useState } from 'react'
import { ACTIONS } from 'src/auth/utils/defineAbilityFor'
import { AlertSeverity, AlertState } from 'src/constants/alert'
import { AbilityContext, Can } from 'src/context/canContext'
import { useAppSelector } from 'src/hooks/hooks'
import NotificationDialog from 'src/pages/Notifications/components/NotificationDialog'
import { useAlertFindQuery } from 'src/services/switch-rest-api/alerts.service'

const NotificationsBadge = styled(Badge)(
    ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: '';
        }
    }
`
)

const getListItemIcon = (severity: AlertSeverity) => {
    switch (severity) {
        case AlertSeverity.Info:
            return (
                <ListItemIcon>
                    <Avatar sx={{ backgroundColor: colors.green[900] }}>
                        <Info />
                    </Avatar>
                </ListItemIcon>
            )
        case AlertSeverity.Error:
            return (
                <ListItemIcon>
                    <Avatar sx={{ backgroundColor: colors.red[900] }}>
                        <ErrorOutline />
                    </Avatar>
                </ListItemIcon>
            )
        case AlertSeverity.Warning:
            return (
                <ListItemIcon>
                    <Avatar sx={{ backgroundColor: colors.yellow[900] }}>
                        <Warning />
                    </Avatar>
                </ListItemIcon>
            )
        default:
            return (
                <ListItemIcon>
                    <Avatar>
                        <Drafts />
                    </Avatar>
                </ListItemIcon>
            )
    }
}

function HeaderNotifications() {
    const {
        applicationNetworkStatus,
        locallySelectedApplicationId,
        manualApplicationChange,
    } = useAppSelector((state) => state.application)
    const {
        applicationGroupNetworkStatus,
        locallySelectedApplicationGroupId,
        manualApplicationGroupChange,
    } = useAppSelector((state) => state.applicationGroup)

    const [isDialogOpen, setIsDialogOpen] = useState(true)
    const [selectedNotification, setSelectedNotification] = useState<
        Notification | undefined
    >()

    const ability = useContext(AbilityContext)

    const {
        data: alerts,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        refetch,
    } = useAlertFindQuery(
        {
            limit: '1000',
            state: AlertState.Active,
            ...(locallySelectedApplicationId !== 'All' && {
                applicationId: locallySelectedApplicationId,
            }),
            applicationGroupId: locallySelectedApplicationGroupId,
        },
        {
            skip:
                !locallySelectedApplicationId ||
                !locallySelectedApplicationGroupId,
        }
    )

    const ref = useRef<any>(null)
    const [isOpen, setOpen] = useState<boolean>(false)

    const handleOpen = (): void => {
        setOpen(true)
    }

    const handleClose = (): void => {
        setOpen(false)
    }

    useEffect(() => {
        if (ability.can(ACTIONS.READ, 'Alerts')) {
            const interval = setInterval(() => {
                refetch()
            }, 90000)
            return () => clearInterval(interval)
        }
    }, [])

    return (
        <>
            <Can I={ACTIONS.READ} a="Alerts">
                <IconButton aria-label="notifications" onClick={handleOpen}>
                    <NotificationsBadge
                        badgeContent={alerts?.totalRecords ?? 0}
                        color="primary"
                    >
                        <Notifications color="action" />
                    </NotificationsBadge>
                </IconButton>
            </Can>

            <Popover
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                slotProps={{
                    paper: {
                        style: { maxWidth: 500, width: '50%' },
                    },
                }}
            >
                <Grid
                    container
                    sx={{ p: 2 }}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    flexDirection={'row'}
                >
                    <Grid item flexGrow={1}>
                        <Typography variant="h5">Alerts</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider />
                <List sx={{ p: 0 }}>
                    {isLoading || isFetching ? (
                        <ListItem>
                            <Skeleton />
                        </ListItem>
                    ) : (
                        alerts?.data
                            .filter((ele) => {
                                if (locallySelectedApplicationId !== 'All') {
                                    return (
                                        ele.scope.applicationId ===
                                        locallySelectedApplicationId
                                    )
                                } else {
                                    return (
                                        ele.scope.applicationGroupId ===
                                        locallySelectedApplicationGroupId
                                    )
                                }
                            })
                            .map((alert) => (
                                <ListItem
                                    key={alert._id}
                                    // onClick={() => {
                                    //   setSelectedNotification(notification)
                                    //   setIsDialogOpen(true)
                                    // }}
                                >
                                    {getListItemIcon(alert.severity)}
                                    <ListItemText
                                        primary={alert.cause.description}
                                        secondary={`Last triggered at ${dayjs(
                                            alert.lastTriggeredAt
                                        ).format('HH:mm, D MMMM YYYY')}`}
                                    />
                                </ListItem>
                            ))
                    )}
                    {alerts?.data.filter((ele) => {
                        if (locallySelectedApplicationId !== 'All') {
                            return (
                                ele.scope.applicationId ===
                                locallySelectedApplicationId
                            )
                        } else {
                            return (
                                ele.scope.applicationGroupId ===
                                locallySelectedApplicationGroupId
                            )
                        }
                    }).length === 0 && (
                        <ListItem>
                            <ListItemText primary="No alerts currently. Add meter rules to be notified of unusual usage" />
                        </ListItem>
                    )}
                </List>
            </Popover>
            {isDialogOpen && selectedNotification && (
                <NotificationDialog
                    isOpen={isDialogOpen}
                    notification={selectedNotification}
                    handleDialogClose={() => {
                        setSelectedNotification(undefined)
                        setIsDialogOpen(false)
                    }}
                />
            )}
        </>
    )
}

export default HeaderNotifications
