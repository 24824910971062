import {
    Language,
    Lock,
    MailOutline,
    VisibilityOffOutlined,
    VisibilityOutlined,
} from '@mui/icons-material'
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    CircularProgress,
    Collapse,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    Tooltip,
    Typography,
} from '@mui/material'
import React, { useEffect, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import googleLogo from 'src/assets/images/google-icon.png'
import { themeColors } from 'src/brands/switch/lightTheme'
import { CustomTextField } from 'src/components/Textinput/Textfield'
import { AuthWrapper } from 'src/pages/Login/components/LoginWrapper'
import globalStyles from '../../../utils/constants/globalStyles'
import { useAuth } from '../../hooks/useAuth'
import {
    actionTypes,
    initialState,
    loginFormReducer,
    resetActionTypes,
    resetPasswordInitialState,
    resetPasswordReducer,
} from '../../reducers/loginFormReducer'

export const Login = () => {
    const navigate = useNavigate()
    const [state, dispatch] = useReducer(loginFormReducer, initialState)
    const [resetPasswordState, resetPasswordDispatch] = useReducer(
        resetPasswordReducer,
        resetPasswordInitialState
    )
    const [showPassword, setShowPassword] = useState(false)
    const [showCard1, setShowCard1] = useState(true)

    const {
        loginWithEmailAndPassword,
        loginWithGoogle,
        isAuthenticated,
        loading,
        resetPassword,
    } = useAuth()

    const handleClickShowPassword = () =>
        setShowPassword((prevState) => !prevState)

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/')
        }
    }, [isAuthenticated])

    const loginUser = async (e: React.SyntheticEvent) => {
        e.preventDefault()
        try {
            loginWithEmailAndPassword(state.email.value, state.password.value)
        } catch (err) {
            console.error(err)
        }
    }

    const handleFormToggle = () => {
        setShowCard1((prev) => !prev)
    }

    const sendResetLink = async (e: React.SyntheticEvent) => {
        e.preventDefault()
        try {
            resetPassword(resetPasswordState.email.value)
            setShowCard1((prev) => !prev)
        } catch (err) {
            console.error(err)
        }
    }

    return (
        <AuthWrapper>
            <Collapse in={showCard1} timeout="auto" sx={{ width: '100%' }}>
                <div
                    style={{
                        margin: 5,
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <CardContent
                        style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 20,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Button
                            sx={{
                                backgroundColor: 'white',
                                color: '#757575',
                                '&:hover': {
                                    backgroundColor: '#fff',
                                },
                            }}
                            onClick={async () => loginWithGoogle()}
                            disabled={loading}
                            variant="contained"
                            size="large"
                        >
                            <img
                                alt="Google Icon"
                                src={googleLogo}
                                style={{
                                    width: '2rem',
                                    height: 'auto',
                                    marginRight: 2,
                                }}
                            />
                            Sign in with Google
                        </Button>
                    </CardContent>
                    <Divider
                        style={{
                            marginTop: 15,
                            paddingLeft: 69,
                            paddingRight: 69,
                            color: 'grey',
                        }}
                    >
                        or
                    </Divider>
                    <CardContent
                        style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Box
                            component="form"
                            onSubmit={loginUser}
                            sx={{
                                '& .MuiTextField-root': { width: '25ch' },
                                gap: 2,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <CustomTextField
                                    required
                                    variant="outlined"
                                    id="outlined-required"
                                    label="Email"
                                    disabled={loading}
                                    style={globalStyles.textField}
                                    autoComplete={'email'}
                                    value={state.email.value}
                                    error={!state.email.valid}
                                    helperText={state.email.error}
                                    onChange={(event) =>
                                        dispatch({
                                            type: actionTypes.EMAIL,
                                            payload: event.target.value,
                                        })
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <MailOutline />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div>
                                <CustomTextField
                                    variant="outlined"
                                    id="outlined-password-input"
                                    label="Password"
                                    type={showPassword ? 'text' : 'password'}
                                    disabled={loading}
                                    style={globalStyles.textField}
                                    autoComplete="current-password"
                                    error={!state.password.valid}
                                    helperText={state.password.error}
                                    value={state.password.value}
                                    onChange={(event) =>
                                        dispatch({
                                            type: actionTypes.PASSWORD,
                                            payload: event.target.value,
                                        })
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip
                                                    title={
                                                        showPassword
                                                            ? 'Hide Password'
                                                            : 'Show Password'
                                                    }
                                                >
                                                    <IconButton
                                                        style={{ padding: 0 }}
                                                        aria-label="toggle password visibility"
                                                        onClick={
                                                            handleClickShowPassword
                                                        }
                                                        onMouseDown={
                                                            handleClickShowPassword
                                                        }
                                                    >
                                                        {showPassword ? (
                                                            <VisibilityOutlined />
                                                        ) : (
                                                            <VisibilityOffOutlined />
                                                        )}
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <FormControlLabel
                                        control={<Checkbox defaultChecked />}
                                        label="Remember me"
                                    />
                                    <Link
                                        onClick={handleFormToggle}
                                        underline="always"
                                        variant="body2"
                                        color={'primary'}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        Forgot password?
                                    </Link>
                                </div>
                            </div>

                            <CardActions
                                sx={{
                                    justifyContent: 'center',
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                }}
                            >
                                <Button
                                    variant="contained"
                                    type="submit"
                                    fullWidth
                                    disabled={loading}
                                    color="primary"
                                    endIcon={
                                        loading ?? (
                                            <CircularProgress
                                                sx={{ color: 'white' }}
                                                size="1em"
                                            />
                                        )
                                    }
                                >
                                    Login
                                </Button>
                            </CardActions>
                        </Box>
                    </CardContent>
                </div>
            </Collapse>
            <Collapse in={!showCard1} timeout="auto">
                <Card>
                    <CardContent>
                        <Box
                            component="form"
                            onSubmit={sendResetLink}
                            noValidate
                            autoComplete="off"
                        >
                            <Lock sx={{ width: 60, height: 60 }} />
                            <Typography
                                align="center"
                                variant="h3"
                                gutterBottom
                            >
                                Password reset
                            </Typography>
                            <Typography
                                sx={{ color: '#000000' }}
                                align="center"
                                variant="subtitle1"
                                gutterBottom
                            >
                                Please enter your email address and we will send
                                you a link to reset your password
                            </Typography>
                            <CustomTextField
                                sx={{
                                    margin: `4px 0`,
                                    width: '100%',
                                    flex: 1,
                                    my: 4,
                                }}
                                // disabled={ userProfileNetworkStatus.sendResetEmail === NetworkState.PENDING }
                                label="Email"
                                variant="outlined"
                                type="email"
                                error={!resetPasswordState.email.valid}
                                helperText={resetPasswordState.email.error}
                                value={resetPasswordState.email.value}
                                onChange={(event) =>
                                    resetPasswordDispatch({
                                        type: resetActionTypes.EMAIL,
                                        payload: event.target.value,
                                    })
                                }
                            />
                            <Button
                                sx={{ margin: `4px 0` }}
                                color="primary"
                                variant="contained"
                                size="large"
                                fullWidth
                                type="submit"
                            >
                                Send Reset Link
                            </Button>
                            <Divider
                                style={{
                                    marginTop: 5,
                                    marginBottom: 5,
                                    borderBottomWidth: 1,
                                }}
                            />
                            <Button
                                sx={{ margin: `4px 0` }}
                                variant="outlined"
                                size="large"
                                fullWidth
                                onClick={handleFormToggle}
                            >
                                Return to login
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Collapse>
            <Grid
                container
                flexDirection={'row'}
                sx={{ width: '100%', mb: 3 }}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Typography>Powered by</Typography>
                <Button
                    endIcon={<Language />}
                    sx={{ color: themeColors.primary }}
                    href="https://www.switch.org.za"
                    component={Link}
                >
                    Switch Energy
                </Button>
            </Grid>
            <Typography variant="body1" align="center">
                {'Copyright © '}
                <Link
                    href="https://www.switch.org.za/privacy-policy"
                    underline="hover"
                >
                    Switch Energy (PTY) LTD
                </Link>{' '}
                {new Date().getFullYear()}
            </Typography>
        </AuthWrapper>
    )
}
