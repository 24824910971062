import { InfoOutlined } from '@mui/icons-material'
import { Divider, Grid, Tooltip, Typography } from '@mui/material'
import { UtilityMetricData } from 'src/constants'

interface Props {
    metrics: UtilityMetricData[]
    unit: string
}

export default function UtilityDataMetrics({ metrics, unit }: Props) {
    function MetricItem({ title, metric }: { title: string; metric: string }) {
        return (
            <Grid container item flexDirection={'row'}>
                <Grid item xs>
                    <Typography>{`${title}:`}</Typography>
                </Grid>
                <Grid item>
                    <Typography textAlign={'end'}>{metric}</Typography>
                </Grid>
            </Grid>
        )
    }

    function renderMetric(metric: UtilityMetricData) {
        return (
            <Grid
                container
                item
                flexDirection={'column'}
                width={280}
                marginBottom={0.5}
            >
                <Grid item>
                    <Typography variant="h4" textAlign={'center'}>
                        {metric.title}
                    </Typography>
                </Grid>
                <MetricItem
                    title={'Total'}
                    metric={`${((metric.total ?? 0) / 1000).toFixed(
                        3
                    )} ${unit}`}
                />
                <MetricItem
                    title={'Average'}
                    metric={`${((metric.average ?? 0) / 1000).toFixed(
                        3
                    )} ${unit}`}
                />
                <MetricItem
                    title={'Min Usage'}
                    metric={`${((metric.min ?? 0) / 1000).toFixed(3)} ${unit}`}
                />
                <MetricItem
                    title={'Max Usage'}
                    metric={`${((metric.max ?? 0) / 1000).toFixed(3)} ${unit}`}
                />
                <MetricItem
                    title={'Start Reading'}
                    metric={`${((metric.start ?? 0) / 1000).toFixed(
                        3
                    )} ${unit}`}
                />
                <MetricItem
                    title={'End Reading'}
                    metric={`${((metric.end ?? 0) / 1000).toFixed(3)} ${unit}`}
                />
                <Grid item>
                    <Divider />
                </Grid>
            </Grid>
        )
    }

    function renderTitle() {
        return (
            <Grid container flexDirection={'column'} width={300}>
                {metrics.map((metric) => {
                    const Component = renderMetric(metric)
                    return Component
                })}
            </Grid>
        )
    }

    return (
        <Tooltip title={renderTitle()} placement="bottom">
            <InfoOutlined />
        </Tooltip>
    )
}
