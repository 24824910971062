import {
    AggregateP1ReadingsByBucketInput,
    AggregateP1ReadingsByBucketResult,
    AggregateP1ReadingsByMeterInput,
    AggregateP1ReadingsByMeterResult,
    AggregateP1SplitInput,
    AggregateP1SplitOutput,
    CreateMutationUtilityDataDto,
    MutationBase,
    ScaleUtilityDataMutation,
    SearchUtilityDataDto,
    SearchUtilityDataResultDto,
    SuggestedPulseRateInput,
    SuggestedPulseRateOutput,
    UtilityData,
} from 'switch-shared'
import { baseAPI } from './baseAPI.service'
import {
    API_METHODS,
    API_ROUTES,
    ENDPOINT_URLS,
    Endpoint,
} from './config/apiTags'

export const utilityDataAPI = baseAPI
    .enhanceEndpoints({
        addTagTypes: [Endpoint.UTILITY_DATA],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getUtilityData: builder.query<
                SearchUtilityDataResultDto,
                SearchUtilityDataDto
            >({
                query: (params) => {
                    return {
                        method: API_METHODS.GET,
                        url: API_ROUTES.utilityData.base,
                        params,
                    }
                },
            }),
            getUtilityDataByBucket: builder.query<
                AggregateP1ReadingsByBucketResult,
                AggregateP1ReadingsByBucketInput
            >({
                query: (params) => {
                    return {
                        method: API_METHODS.GET,
                        url: API_ROUTES.utilityData.aggregate,
                        params,
                    }
                },
            }),
            getAggregateP1Split: builder.query<
                AggregateP1SplitOutput,
                AggregateP1SplitInput
            >({
                query: (params) => {
                    return {
                        method: API_METHODS.GET,
                        url: API_ROUTES.utilityData.p1Split,
                        params,
                    }
                },
            }),
            getAggregateP1ByMeter: builder.query<
                AggregateP1ReadingsByMeterResult,
                AggregateP1ReadingsByMeterInput
            >({
                query: (params) => {
                    return {
                        method: API_METHODS.GET,
                        url: `${ENDPOINT_URLS.UTILITY_DATA}/aggregateP1ReadingsByMeter`,
                        params,
                    }
                },
            }),
            scaleUtilityData: builder.mutation<
                UtilityData[],
                ScaleUtilityDataMutation
            >({
                query: (data) => ({
                    url: `${ENDPOINT_URLS.UTILITY_DATA}/scaleUtilityData`,
                    method: API_METHODS.PATCH,
                    body: data,
                }),
            }),
            deleteUtilityData: builder.mutation<any, MutationBase>({
                query: (data) => ({
                    url: `${ENDPOINT_URLS.UTILITY_DATA}/deleteUtilityData`,
                    method: API_METHODS.PATCH,
                    body: data,
                }),
            }),
            createUtilityData: builder.mutation<
                UtilityData,
                CreateMutationUtilityDataDto
            >({
                query: (data) => ({
                    url: `${ENDPOINT_URLS.UTILITY_DATA}`,
                    method: API_METHODS.POST,
                    body: data,
                }),
            }),
            getSuggestedPulseRate: builder.query<
                SuggestedPulseRateOutput,
                SuggestedPulseRateInput
            >({
                query: (params) => ({
                    url: `${ENDPOINT_URLS.UTILITY_DATA}/suggestedPulseRate`,
                    method: API_METHODS.GET,
                    params,
                }),
            }),
        }),
    })

export const {
    useGetUtilityDataQuery,
    useLazyGetUtilityDataQuery,
    useGetUtilityDataByBucketQuery,
    useGetAggregateP1SplitQuery,
    useDeleteUtilityDataMutation,
    useScaleUtilityDataMutation,
    useGetAggregateP1ByMeterQuery,
    useCreateUtilityDataMutation,
    useLazyGetSuggestedPulseRateQuery,
} = utilityDataAPI
export default utilityDataAPI
