export enum MANUFACTURERS {
    KAMSTRUP = 'kamstrup',
    SENSUS = 'sensus',
    RTS = 'rts',
    BRIDGEIOT = 'bridgiot',
    SWITCH = 'switch',
    MACROCOMM = 'macrocomm',
    EASTRON = 'eastron',
    HONEYWELL = 'honeywell',
    HEXING = 'hexing',
    ELSTER = 'elster',
    ZZ_METERING = 'zz-metering',
    VISIOSOFT = 'visiosoft',
    AMBER = 'amber',
    METER_MATE = 'meter-mate',
    LIVEWIRE = 'livewire',
}

export enum PULSE_RATES {
    ZERO_POINT_ONE = 0.1,
    ZERO_POINT_FIVE = 0.5,
    ONE = 1,
    ONE_POINT_FIVE = 1.5,
    FIVE = 5,
    TEN = 10,
    TWENTY_FIVE = 25,
    FIFTY = 50,
    HUNDRED = 100,
    THOUSAND = 1000,
}

export enum MeterType {
    Modbus = 'modbus',
    Sigfox = 'sigfox',
    Lora = 'lora',
}

export const MeterPulseRates = [
    {
        name: '0.1L/pulse',
        value: PULSE_RATES.ZERO_POINT_ONE,
    },
    {
        name: '0.5L/pulse',
        value: PULSE_RATES.ZERO_POINT_FIVE,
    },
    {
        name: '1L/pulse',
        value: PULSE_RATES.ONE,
    },
    {
        name: '1.5L/pulse',
        value: PULSE_RATES.ONE_POINT_FIVE,
    },
    {
        name: '5L/pulse',
        value: PULSE_RATES.FIVE,
    },
    {
        name: '10L/pulse',
        value: PULSE_RATES.TEN,
    },
    {
        name: '100L/pulse',
        value: PULSE_RATES.HUNDRED,
    },
    {
        name: '1000L/pulse',
        value: PULSE_RATES.THOUSAND,
    },
]

export const MeterModels = [
    {
        key: MANUFACTURERS.KAMSTRUP,
        displayName: 'Kamstrup',
        models: [
            {
                key: 'mc21',
                displayName: 'Multical 21',
                customFields: ['aesKey'],
                UtilityType: 'water',
                communicationTypes: [
                    {
                        key: 'sigfox',
                        displayName: 'Sigfox',
                        customFields: ['pac', 'sigfoxId'],
                    },
                    {
                        key: 'wireless-modbus',
                        displayName: 'Wireless Modbus',
                        customFields: ['decryption'],
                    },
                ],
            },
            {
                key: 'flowiq',
                displayName: 'Flow IQ',
                customFields: ['aesKey'],
                UtilityType: 'water',
            },
        ],
    },
    {
        key: MANUFACTURERS.SENSUS,
        displayName: 'Sensus',
    },
    {
        key: MANUFACTURERS.RTS,
        displayName: 'RTS',
    },
    {
        key: MANUFACTURERS.BRIDGEIOT,
        displayName: 'Bridgiot',
    },
    {
        key: MANUFACTURERS.SWITCH,
        displayName: 'Switch',
    },
    {
        key: MANUFACTURERS.MACROCOMM,
        displayName: 'Macrocomm',
    },
    {
        key: MANUFACTURERS.VISIOSOFT,
        displayName: 'Visiosoft',
        models: [
            {
                key: 'opticalv2',
                displayName: 'Opticalv2',
                UtilityType: 'water',
            },
            {
                key: 'aqua',
                displayName: 'Aqua',
                UtilityType: 'water',
            },
        ],
    },
    {
        key: MANUFACTURERS.EASTRON,
        displayName: 'Eastron',
        models: [
            {
                key: 'sdm320c',
                displayName: 'SDM320C (1P Relay)',
                customFields: ['modbusId'],
                UtilityType: 'water',
            },
            {
                key: 'sdm530c',
                displayName: 'SDM530C (3P Relay)',
                customFields: ['modbusId'],
                UtilityType: 'electricity',
            },
            {
                key: 'sdm72ct-m',
                displayName: 'SDM72CT-M',
                customFields: ['modbusId'],
                UtilityType: 'electricity',
            },
        ],
    },
    {
        key: MANUFACTURERS.HONEYWELL,
        displayName: 'Honeywell',
        models: [
            {
                key: 'ad120',
                displayName: 'AD120',
                customFields: ['modbusId'],
                UtilityType: 'water',
            },
            {
                key: 'ad1200',
                displayName: 'AD1200',
                customFields: ['modbusId'],
                UtilityType: 'water',
            },
            {
                key: 'HS100',
                displayName: 'HS100',
                customFields: ['modbusId'],
                UtilityType: 'electricity',
            },
        ],
    },
    {
        key: MANUFACTURERS.HEXING,
        displayName: 'Hexing',
    },
    {
        key: MANUFACTURERS.ELSTER,
        displayName: 'Elster',
    },
    {
        key: MANUFACTURERS.ZZ_METERING,
        displayName: 'ZZ-Metering',
    },
    {
        key: MANUFACTURERS.AMBER,
        displayName: 'Amber',
        models: [
            {
                key: 'pulse',
                displayName: 'Pulse',
            },
        ],
    },
]

export const MeterRuleAggregators = [
    {
        value: 'sum',
        label: 'SUM',
    },
    {
        value: 'avg',
        label: 'AVG',
    },
    {
        value: 'max',
        label: 'MAX',
    },
    {
        value: 'min',
        label: 'MIN',
    },
]

export const MeterRuleOperators = [
    {
        value: 'lt',
        label: '<',
        word: 'than',
    },
    {
        value: 'lte',
        label: '≤',
        word: 'to',
    },
    {
        value: 'gt',
        label: '>',
        word: 'than',
    },
    {
        value: 'gte',
        label: '≥',
        word: 'to',
    },
    {
        value: 'eq',
        label: '=',
        word: 'to',
    },
]

export const MeterRuleTimeframes = [
    {
        value: '15mins',
        duration: 900,
        label: '15 mins',
    },
    {
        value: '30mins',
        duration: 1800,
        label: '30 mins',
    },
    {
        value: '1hour',
        duration: 3600,
        label: '1 hour',
    },
    {
        value: '2hours',
        duration: 7200,
        label: '2 hours',
    },
    {
        value: '3hours',
        duration: 10800,
        label: '3 hours',
    },
    {
        value: '4hours',
        duration: 14400,
        label: '4 hours',
    },
    {
        value: '6hours',
        duration: 21600,
        label: '6 hours',
    },
    {
        value: '12hours',
        duration: 43200,
        label: '12 hours',
    },
    {
        value: '24hours',
        duration: 86400,
        label: '24 hours',
    },
    {
        value: '1week',
        duration: 86400,
        label: '1 week',
    },
]
