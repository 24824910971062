import { Close } from '@mui/icons-material'
import {
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import UsageChart from 'src/components/Charts/UsageChart'
import { UtilityTypeBadge } from 'src/components/Chips/UtilityChip'
import { DateRangeType } from 'src/constants'
import { BucketUnit } from 'src/constants/general'
import { useGetUtilityDataByBucketQuery } from 'src/services/switch-rest-api/utility-data.service'
import { roundUsage } from 'src/utils/formatters'

interface Props {
    isOpen: boolean
    handleDialogClose: () => void
    notification: any
}

const NotificationDialog = ({
    isOpen,
    handleDialogClose,
    notification,
}: Props) => {
    interface CalculateStartTimeEndTime {
        startTimeTemp: string
        endTimeTemp: string
    }

    const calculateStartTimeEndTime = ({
        startTimeTemp,
        endTimeTemp,
    }: CalculateStartTimeEndTime) => {
        const diff = dayjs(startTimeTemp).diff(endTimeTemp, 'day')

        const interval = 1440
        const startTime = dayjs(startTimeTemp).startOf('day').toISOString()
        const endTime = dayjs(endTimeTemp).endOf('day').toISOString()

        // switch (true) {
        // case (diff === 0):
        //     interval = 3600
        //     startTime = dayjs(startTimeTemp).startOf('day').toISOString()
        //     endTime = dayjs(endTimeTemp).endOf('day').toISOString()
        //     break
        // default:
        //     interval = 86400
        //     startTime = dayjs(startTimeTemp).startOf('day').toISOString()
        //     endTime = dayjs(endTimeTemp).endOf('day').toISOString()
        //     break
        // }

        return { interval, startTime, endTime }
    }

    const params = calculateStartTimeEndTime({
        startTimeTemp: notification.startTime,
        endTimeTemp: notification.endTime,
    })

    const {
        isLoading: isUtilityDataLoading,
        data: utilityData,
        isSuccess: isUtilityDataSuccess,
        isFetching: isUtilityDataFetching,
        isError,
        refetch,
    } = useGetUtilityDataByBucketQuery(
        {
            meterIds: [notification.meterId],
            startTimeISO: params.startTime,
            endTimeISO: params.endTime,
            bucketSize: params.interval,
            bucketUnit: BucketUnit.Minute,
        },
        { skip: !notification.meterId }
    )

    return (
        <Dialog
            fullWidth
            maxWidth="lg"
            open={isOpen}
            onClose={handleDialogClose}
        >
            <DialogContent>
                <Card>
                    <CardHeader
                        avatar={UtilityTypeBadge({
                            type: notification.utilityType,
                        })}
                        action={
                            <IconButton
                                aria-label="back"
                                onClick={handleDialogClose}
                            >
                                <Close />
                            </IconButton>
                        }
                        title="Notification Details"
                        subheader={`${notification.notificationType.toUpperCase()} at ${
                            notification.applicationName
                        } for meter: ${notification.meterName} | FROM ${dayjs(
                            notification.startTime
                        ).format('YYYY-MM-DD HH:mm')} TO ${dayjs(
                            notification.endTime
                        ).format('YYYY-MM-DD HH:mm')}`}
                    />
                    <CardContent>
                        {isUtilityDataLoading ? (
                            <div
                                style={{
                                    height: 300,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <CircularProgress />
                            </div>
                        ) : utilityData?.buckets.length !== 0 ? (
                            <Grid container>
                                <Grid item xs={12}>
                                    <UsageChart
                                        series={
                                            utilityData
                                                ? utilityData.buckets
                                                : []
                                        }
                                        groups={[
                                            {
                                                name: 'Usage',
                                                group: 'Usage',
                                                data: (utilityData
                                                    ? utilityData.buckets
                                                    : []
                                                ).map((ele) =>
                                                    roundUsage(
                                                        ele.deltaP1 / 1000,
                                                        2
                                                    )
                                                ),
                                            },
                                        ]}
                                        utilityType={notification.utilityType}
                                        extraChartOptions={{
                                            chart: {
                                                type: 'bar',
                                                height: 350,
                                            },
                                        }}
                                        isLoading={isUtilityDataLoading}
                                        isFetching={isUtilityDataFetching}
                                        isSuccess={isUtilityDataSuccess}
                                        isError={isError}
                                        bucketType={'timestampISO'}
                                        dateRangeFilter={DateRangeType.WEEK}
                                        startTime={notification.startTime}
                                        endTime={notification.endTime}
                                    />
                                </Grid>
                            </Grid>
                        ) : (
                            <div
                                style={{
                                    height: 300,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography variant="h5" align="center">
                                    There is no data associated with this meter
                                    or something went wrong
                                </Typography>
                            </div>
                        )}
                    </CardContent>
                </Card>
            </DialogContent>
        </Dialog>
    )
}

export default NotificationDialog
