export enum AlertState {
    Active = 'active',
    Resolved = 'resolved',
}

export enum AlertSeverity {
    Info = 'info',
    Warning = 'warning',
    Error = 'error',
}

export enum CauseType {
    MeterInfoCode = 'meter-info-code',
    MeterRule = 'meter-rule',
}
