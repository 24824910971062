//@ts-nocheck
import { CircularProgress, Grid, Typography } from '@mui/material'
import { ApexOptions } from 'apexcharts'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import Chart from 'react-apexcharts'
import { DateRangeType, UtilityType } from 'src/constants'
import { getUtilityUnits, getYAxisLabel } from 'src/utils/formatters'
import UtilityDataMetrics from '../Tooltips/UtilityDataMetrics'

const UsageChart = ({
    series,
    utilityType,
    extraChartOptions,
    isError,
    isLoading,
    isSuccess,
    isFetching,
    bucketType,
    dateRangeFilter,
    startTime,
    endTime,
    groups,
    title,
    showMetrics,
    metricTotal,
    metricMin,
    metricMax,
    metricStart,
    metricEnd,
    metricIntervals,
}: {
    series: any[]
    utilityType: UtilityType
    extraChartOptions: ApexOptions
    isError: boolean
    isLoading: boolean
    isSuccess: boolean
    isFetching: boolean
    bucketType: string
    dateRangeFilter: DateRangeType
    startTime: string
    endTime: string
    groups: any[]
    title?: string
    showMetrics?: boolean
    metricTotal?: number
    metricMin?: number
    metricMax?: number
    metricStart?: number
    metricEnd?: number
    metricIntervals?: number
}) => {
    const formatUsageChartXAxisDate = (timestampISO: string) => {
        if (
            dateRangeFilter === DateRangeType.PAST_24_HOURS ||
            dateRangeFilter === DateRangeType.TODAY ||
            dayjs(endTime).diff(dayjs(startTime), 'day') === 0
        ) {
            return dayjs(timestampISO).format('hA')
        }

        return dayjs(timestampISO).format('D MMM YY')
    }

    const chart_options = useMemo<ApexOptions>(() => {
        return {
            ...extraChartOptions,
            chart: {
                ...extraChartOptions.chart,
                type: 'bar',
                height: '100%',
                stacked: true,
                group: 'social',
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded',
                },
            },
            grid: {
                show: true,
                xaxis: {
                    lines: {
                        show: false,
                    },
                },
                yaxis: {
                    lines: {
                        show: true,
                    },
                },
            },
            colors: extraChartOptions.colors,
            export: {
                csv: {
                    filename: undefined,
                    columnDelimiter: ',',
                    headerCategory: 'category',
                    headerValue: 'value',
                    dateFormatter(timestamp: string) {
                        return new Date(timestamp).toDateString()
                    },
                },
                svg: {
                    filename: undefined,
                },
                png: {
                    filename: undefined,
                },
            },
            xaxis: {
                categories:
                    bucketType === 'application'
                        ? series.map((ele: string) => {
                              if (ele.name && ele.name.length >= 15) {
                                  const res = ele.name.substring(0, 15) + '...'
                                  return res
                              } else {
                                  return ele.name
                              }
                          })
                        : series.map((ele) =>
                              formatUsageChartXAxisDate(ele.timestampISO)
                          ),
                labels: {
                    rotate: -45,
                    rotateAlways: true,
                    formatter: function (val: string) {
                        return val
                    },
                },
            },
            yaxis: {
                title: {
                    text: title
                        ? `${title} (${getUtilityUnits(utilityType, true)})`
                        : getYAxisLabel(utilityType),
                },
                labels: {
                    formatter: function (val) {
                        return val.toFixed(2)
                    },
                },
            },
            tooltip: {
                y: {
                    formatter: function (val: number) {
                        return `${val.toFixed(2)} ${getUtilityUnits(
                            utilityType,
                            true
                        )}`
                    },
                },
                shared: true,
                intersect: false,
            },
            fill: {
                opacity: 1,
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40,
            },
            dataLabels: {
                enabled: false,
            },
        }
    }, [extraChartOptions, series])

    const chart_series = useMemo(() => {
        return groups
    }, [series])

    return isLoading || isFetching ? (
        <Grid
            container
            justifyContent={'center'}
            alignItems={'center'}
            sx={{ height: '100%' }}
        >
            <CircularProgress />
        </Grid>
    ) : (
        <Grid container height={'100%'}>
            <Grid container item alignItems={'center'} gap={2}>
                {title !== undefined && (
                    <Grid item flexDirection={'row'}>
                        <Typography variant="h4">{title}</Typography>
                    </Grid>
                )}
                {showMetrics && (
                    <Grid item>
                        <UtilityDataMetrics
                            metricTitle={title}
                            utilityType={utilityType}
                            intervals={metricIntervals}
                            total={metricTotal}
                            min={metricMin}
                            max={metricMax}
                            start={metricStart}
                            end={metricEnd}
                        />
                    </Grid>
                )}
            </Grid>
            <Grid item xs={12} height={'100%'}>
                <Chart
                    options={chart_options}
                    series={chart_series}
                    height={'100%'}
                    type="bar"
                />
            </Grid>
        </Grid>
    )
}

export default UsageChart
