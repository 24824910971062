import { ExpandMoreTwoTone, LogoutOutlined } from '@mui/icons-material'
import {
    Avatar,
    Box,
    Button,
    colors,
    Divider,
    Hidden,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Popover,
    styled,
    Typography,
    useTheme,
} from '@mui/material'
import { SetStateAction, useState } from 'react'
import { useSelector } from 'react-redux'
import { NavLink as RouterLink } from 'react-router-dom'
import { logout } from 'src/auth'
import details from 'src/brands/switch/details.json'
import data from '../../../package.json'

const MenuUserBox = styled(Box)(
    ({ theme }) => `
        // background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
)

const UserBoxText = styled(Box)(
    ({ theme }) => `
        text-align: center;
        padding-left: ${theme.spacing(1)};
`
)

const UserBoxLabel = styled(Typography)(
    ({ theme }) => `
    font-weight: ${theme.typography.fontWeightBold};
    color: ${theme.palette.primary.contrastText};
    display: block;`
)

const UserInfo = () => {
    const theme = useTheme()

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const [selectedIndex, setSelectedIndex] = useState(0)
    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: SetStateAction<number>
    ) => {
        setSelectedIndex(index)
    }

    const open = Boolean(anchorEl)

    const user = useSelector((state: any) => state.user) //TODO: ADD IS LOADING AND any ONCE WE KNOW WHAT THE USE WILL BE

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleLogout = () => {
        try {
            logout()
        } catch (err) {
            console.error(err)
        }
    }

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
            <Button
                color="primary"
                onClick={handleClick}
                variant="text"
                sx={{
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                }}
            >
                <Avatar alt={user.user.email} src={user.user.photoURL} />
                <Hidden mdDown>
                    <UserBoxText>
                        <UserBoxLabel variant="body1">
                            {user?.user?.email ?? ''}
                        </UserBoxLabel>
                    </UserBoxText>
                </Hidden>
                <Hidden smDown>
                    <ExpandMoreTwoTone
                        sx={{ ml: 1, color: theme.colors.neutral.grey[40] }}
                    />
                </Hidden>
            </Button>

            <Popover
                anchorEl={anchorEl}
                onClose={handleClose}
                open={open}
                sx={{
                    marginTop: 1,
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuUserBox
                    sx={{ minWidth: 210 }}
                    display="flex"
                    alignItems={'center'}
                >
                    <Avatar
                        alt={user.user.displayName}
                        src={user.user.photoURL}
                    />
                    <UserBoxText>
                        <Typography variant="body1">
                            {user?.user?.email ?? ''}
                        </Typography>
                    </UserBoxText>
                    <UserBoxText></UserBoxText>
                </MenuUserBox>
                <MenuUserBox
                    sx={{ minWidth: 210 }}
                    display="flex"
                    alignItems={'center'}
                >
                    <Divider sx={{ width: '95%' }} />
                </MenuUserBox>
                <List
                    component="nav"
                    sx={{
                        p: 0,
                        '& .MuiListItemIcon-root': {
                            minWidth: 32,
                            color: theme.palette.grey[500],
                        },
                    }}
                >
                    <ListItemButton
                        sx={{
                            borderRadius: '0',
                        }}
                        selected={selectedIndex === 2}
                        onClick={handleLogout}
                    >
                        <ListItemIcon>
                            <LogoutOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItemButton>
                </List>
                <UserBoxText sx={{ mt: 2 }}>
                    <Typography
                        variant="h6"
                        align="center"
                        component={RouterLink}
                        to={`${details.website}`}
                        target="_blank"
                        sx={{
                            color: colors.grey[500],
                            fontSize: 12,
                            justifyContent: 'center',
                        }}
                    >
                        Powered by Switch Energy
                    </Typography>
                </UserBoxText>

                <Typography
                    sx={{
                        color: colors.grey[500],
                        fontSize: 12,
                        mb: 1,
                    }}
                    align="center"
                    component="p"
                >
                    Version {data.version}
                </Typography>
                {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='profile tabs'
            textColor='primary'
            indicatorColor='primary'
          >
            <Tab
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              icon={<Person style={{ marginBottom: 0, marginRight: '10px' }} />}
              label='Profile'
              {...a11yProps(0)}
            />
            <Tab
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
              icon={<Business style={{ marginBottom: 0, marginRight: '10px' }} />}
              label='Traders'
              {...a11yProps(1)}
            />
          </Tabs>
        </Box> */}
                {/* <TabPanel value={value} index={0}>
          <ProfileTab handleLogout={logout} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SettingTab />
        </TabPanel> */}
            </Popover>
        </Box>
    )
}

export default UserInfo
