import { useAbility } from '@casl/react'
import React, { ReactNode, Suspense } from 'react'
import { Navigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Subject } from 'src/auth/utils/defineAbilityFor'
import SuspenseLoader from 'src/components/SuspenseLoader'
import { NetworkState } from 'src/constants/network-state'
import { useAppSelector } from 'src/hooks/hooks'
import pages from 'src/router/routes'
import { AbilityContext } from '../context/canContext'

interface RouteGuardProps {
    allowedActions: string
    children: ReactNode
    subject: Subject
}

const RouteGuard: React.FC<RouteGuardProps> = ({
    allowedActions,
    children,
    subject,
}) => {
    const ability = useAbility(AbilityContext)
    const abilityRoleSlice = useAppSelector((state) => state.abilityRole)

    if (abilityRoleSlice.status !== NetworkState.SUCCESS) {
        return (
            <Suspense fallback={<SuspenseLoader />}>
                <SuspenseLoader />
            </Suspense>
        )
    }

    const hasPermission = ability.can(allowedActions, subject)

    if (!hasPermission) {
        toast.error('You do not have permission to view this page')
        return <Navigate to={`/${pages.status.status404.name}`} />
    }

    return <>{children}</>
}

export default RouteGuard
